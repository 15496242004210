<template>
  <!-- Informations générales communes à toutes les pages -->
  <div class="variables-etudiees-subview">
    <template v-if="isEditable">
      <teleport to="#header-action">
        <Btn
          v-if="helperService.userHasPermission('essaievenement_oedit')"
          class="action-btn"
          icon="create"
          color="white"
          hollow
          text="Modifier"
          :to="{
          name: 'protocolExperimentalTaskVariableCalculatedEdit',
          params: {
            id: $route.params.id,
            tid: $route.params.tid
          }
        }"
        />
      </teleport>
    </template>
  </div>
  <div class="tabs-material-subtitle no-padding-bottom">
    <Container>
      <h2>Variables calculées</h2>
    </Container>
  </div>
  <div class="variables-etudiees-subview">
    <Section>
      <List
        :api="list.api"
        :header="list.headers"
        :items="list.col"
        :item-key="list.key"
        disableCheckbox
        disableActions
      >
        <template v-slot:calcul="{ item }">
          {{ item.calcul.designation }}
        </template>
      </List>
    </Section>
  </div>
</template>

<script>
import Btn from '@/components/base/Btn.vue'
import List from '@/components/list/List.vue'
import Section from '@/components/layout/Section.vue'
import Container from '@/components/layout/Container.vue'

export default {
  name: 'VariablesCalculatedSubview',
  components: {
    Btn,
    List,
    Section,
    Container,
  },

  props: {
    pageTitle: {
      type: String,
    },
    task: {
      type: Object,
    },
    isEditable: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      list: {
        api: {
          endpoint: null,
          params: {
            sort: null,
            page: null,
            limit: 0,
            filters: null,
          },
        },
        headers: [
          { label: 'Designation' },
        ],
        col: ['calcul'],
        key: 'id',
      },
    }
  },
  created() {
    this.list.api.endpoint = `protocole/${this.$route.params.id}/evenement/${this.$route.params.tid}/variable_calculee`
  },
}
</script>

<style lang="scss" scoped>

</style>
